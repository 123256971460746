import React, { lazy, useEffect } from 'react';
import { ConfigProvider, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { CssBaseline, ThemeProvider } from '@mui/material';
import { getLanguageSelector } from '@store/common/selector';
import RTL from '@layouts/components/shared/customizer/RTL';

import locale from 'antd/es/locale/vi_VN';
import localeEn from 'antd/es/locale/en_US';

import { ThemeSettings } from './theme/Theme';
import emptyMatBao from '@assets/images/backgrounds/matbao-404.svg';

//** Load App
const LazyApp = lazy(() => import('./App'));

const InitApp = ({ DefaultRoute, listRoutes, listNav }) => {
  const { i18n, t } = useTranslation();

  const theme = ThemeSettings();
  const lang = useSelector(getLanguageSelector);
  const customizer = useSelector((state) => state?.customizer);

  useEffect(() => {
    if (!!lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return (
    <ConfigProvider
      renderEmpty={() => <Empty description={t('CORE:empty_desc')} image={emptyMatBao} />}
      direction={customizer?.activeDir}
      locale={lang === 'vi' ? locale : localeEn}
      theme={{
        token: {
          fontFamily: "'Roboto', sans-serif",
        },
        components: {
          Table: {
            headerBorderRadius: 0,
          },
        },
      }}
    >
      <ThemeProvider theme={theme}>
        <RTL direction={customizer?.activeDir}>
          <CssBaseline />
          <LazyApp DefaultRoute={DefaultRoute} Routes={listRoutes} Nav={listNav} />
        </RTL>
      </ThemeProvider>
    </ConfigProvider>
  );
};
export default InitApp;
