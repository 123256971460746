import axios from 'axios';
import { BASE_URL_API } from '@configs/api';
import swal from 'sweetalert';
import { logout } from '@utility/function/logout';

const axiosCreate = axios.create({
  baseURL: BASE_URL_API,
  headers: {
    Accept: 'application/json,application/x-www-form-urlencoded,text/plain,*/*',
    'Content-Type': 'application/json;charset=utf-8',
  },
});

axiosCreate.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      swal('Oops! Phiên làm việc đã hết hạn!!!', `Vui lòng đăng nhập lại để tiếp tục`, 'error', {
        closeOnEsc: false,
        closeOnClickOutside: false,
      }).then(() => {
        logout();
        window.location.replace('/');
      });
      return;
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }

    return Promise.reject(error.message);
  },
);

axiosCreate.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    // console.error('network error');
    // console.error(error);
    return Promise.reject(error.message);
  },
);
axiosCreate.interceptors.request.use((request) => {
  // console.log(request);
  return request;
});

export default axiosCreate;
