import intlMessagesEN from './i18n/localization/en.json';
import intlMessagesVi from './i18n/localization/vi.json';

export default {
  name: 'Public',
  dir: 'Public',
  pathRoot: '',
  routes: [
    {
      url: 'identity',
      component: 'Page/Identity',
      meta: {
        authRoute: true,
      },
      layout: 'BlankLayout',
      props: {
        title: 'Trang chủ | Smart Event',
        titleI18n: 'Public:registerEvent',
        headerStyle: 'fill',
        exact: true,
      },
    },
    {
      url: 'thiep-moi-dam-cuoi/:inviteCode',
      component: 'Page/DashboardWedding',
      meta: {
        authRoute: true,
      },
      layout: 'BlankLayout',
      props: {
        title: 'Thiệp mời điện tử | Smart Event',
        titleI18n: 'Public:inviteCardTitle',
        headerStyle: 'fill',
        exact: true,
      },
    },
    {
      url: 'thiep-moi/:inviteCode',
      component: 'Page/Dashboard',
      meta: {
        authRoute: true,
      },
      layout: 'BlankLayout',
      props: {
        title: 'Thiệp mời điện tử | Smart Event',
        titleI18n: 'Public:inviteCardTitle',
        headerStyle: 'fill',
        exact: true,
      },
    },
    {
      url: 'su-kien/dang-ky/:eventId',
      component: 'Page/Register',
      meta: {
        authRoute: true,
      },
      layout: 'BlankLayout',
      props: {
        title: 'Trang chủ | Smart Event',
        titleI18n: 'Public:registerEvent',
        headerStyle: 'fill',
        exact: true,
      },
    },
    {
      url: 'thu-vien-anh/:eventId',
      component: 'Page/Gallery',
      meta: {
        authRoute: true,
      },
      layout: 'BlankLayout',
      props: {
        title: 'Trang chủ | Smart Event',
        titleI18n: 'Public:gallery',
        headerStyle: 'fill',
        exact: true,
      },
    },
    {
      url: 'ket-noi-giao-thuong/:eventId',
      component: 'Page/SaveContact',
      meta: {
        authRoute: true,
      },
      layout: 'BlankLayout',
      props: {
        title: 'Trang chủ | Smart Event',
        titleI18n: 'Public:saveContact',
        headerStyle: 'fill',
        exact: true,
      },
    },
    {
      url: 'huong-dan-thanh-toan-vnpay',
      component: 'Page/VNPay',
      meta: {
        authRoute: true,
      },
      layout: 'BlankLayout',
      props: {
        title: 'Hướng dẫn thanh toán VNPay | Smart Event',
        titleI18n: 'Public:VNPay',
        headerStyle: 'fill',
        exact: true,
      },
    },
    {
      url: 'chinh-sach-bao-mat-thanh-toan',
      component: 'Page/CSBMVNPay',
      meta: {
        authRoute: true,
      },
      layout: 'BlankLayout',
      props: {
        title: 'Hướng dẫn thanh toán VNPay | Smart Event',
        titleI18n: 'Public:VNPay',
        headerStyle: 'fill',
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: 'Any',
};
