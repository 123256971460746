import intlMessagesEN from './i18n/localization/en.json';
import intlMessagesVi from './i18n/localization/vi.json';

export default {
  name: 'DynamicGroupCode',
  dir: 'DynamicGroupCode',
  pathRoot: 'dynamic-group-code',
  routes: [
    {
      url: ':id',
      component: 'Page/CreateEdit',
      meta: {
        authRoute: true,
      },
      layout: 'FullLayout',
      props: {
        title: 'Cập nhật nhóm động | Smart Event',
        titleI18n: 'DynamicGroupCode:updateTab',
      },
    },
    {
      url: '',
      component: 'Page/index',
      meta: {
        authRoute: true,
      },
      layout: 'FullLayout',
      props: {
        title: 'Nhóm động | Smart Event',
        titleI18n: 'DynamicGroupCode:title',
        headerStyle: 'fill',
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  roles: ['superadmin', 'support', 'mkt'],
};
