import intlMessagesEN from './i18n/localization/en.json';
import intlMessagesVi from './i18n/localization/vi.json';

export default {
  name: 'FormPersonalRegister',
  dir: 'FormPersonalRegister',
  pathRoot: 'dang-ky',
  routes: [
    {
      url: ':slug',
      component: 'Page',
      meta: {
        authRoute: true,
      },
      layout: 'BlankLayout',
      props: {
        title: 'Đăng ký sự kiện | Smart Event',
        titleI18n: 'FormPersonalRegister:title',
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: 'Any',
};
