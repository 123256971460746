import intlMessagesEN from './i18n/localization/en.json';
import intlMessagesVi from './i18n/localization/vi.json';

export default {
  name: 'UserInvite',
  dir: 'UserInvite',
  pathRoot: 'user-invite',
  routes: [
    {
      url: '',
      component: 'Page/index',
      meta: {
        authRoute: true,
      },
      layout: 'FullLayout',
      props: {
        title: 'Trang chủ | Smart Event',
        titleI18n: 'UserInvite:title',
        headerStyle: 'fill',
        exact: true,
      },
    },
    {
      url: 'update-user/:id',
      component: 'Page/EditUser',
      meta: {
        authRoute: true,
      },
      layout: 'FullLayout',
      props: {
        title: 'Trang chủ | Smart Event',
        titleI18n: 'UserInvite:title',
        headerStyle: 'fill',
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
};
