import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: undefined,
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAuthError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export default AuthSlice.reducer;
