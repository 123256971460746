import React, { Suspense, useEffect, useState } from 'react';

/*Store state Redux Saga */
import { Provider } from 'react-redux';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { reducerStore } from './store/Store';
import { PersistGate } from 'redux-persist/integration/react';
import immutableTransform from 'redux-persist-transform-immutable';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { initApp } from '@utility/app_provider';

/* Language */
import { I18nextProvider, initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import intlMessagesEn from '@src/i18n/localization/en.json';
import intlMessagesVi from '@src/i18n/localization/vi.json';
// import Spinner from './components/spinner/Fallback-spinner';

// ** Router Default
import { DefaultRoute, Routes } from '@src/router/routes';

//** Load App
import InitApp from './InitApp';

/*Api*/
import apiMethod from '@utility/api_method';

import dayjs from 'dayjs';
import 'dayjs/locale/vi';
import Loading from './components/spin/progress';

dayjs.locale('vi');

const Bootstrap = () => {
  const [loaded, setLoaded] = useState(false);
  const isImpersonation = localStorage.getItem('isImpersonation');

  const accessToken = isImpersonation
    ? localStorage.getItem('impersonationToken')
    : localStorage.getItem('accessToken');
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [modules, setModules] = useState({
    listRoutes: [],
    listCache: [],
    listRedux: reducerStore,
    listLangVi: intlMessagesVi,
    listLangEn: intlMessagesEn,
  });

  useEffect(() => {
    const isAuthenticated = accessToken ? true : false;
    if (isAuthenticated) {
      apiMethod.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }
    const setupApp = async () => {
      try {
        const res = await initApp(isAuthenticated);
        const { resModules } = res;
        setModules((prevModules) => ({
          ...prevModules,
          listRoutes: [...prevModules.listRoutes, ...resModules.listRoutes],
          listCache: Object.values({ ...resModules.listCache }),
          listLangVi: { ...prevModules.listLangVi, ...resModules.listLangVi },
          listLangEn: { ...prevModules.listLangEn, ...resModules.listLangEn },
          listRedux: { ...prevModules.listRedux, ...resModules.initReducer },
        }));
      } catch (e) {
        console.log('Error', e);
      } finally {
        setLoaded(true);
      }
    };
    setupApp();
  }, [accessToken]);

  const { listRedux, listRoutes, listLangVi, listLangEn, listNav, listCache } = modules;

  if (loaded) {
    /*setup redux*/
    const persistConfig = {
      key: 'root',
      transforms: [immutableTransform()],
      storage,
      blacklist: listCache,
    };
    const reducers = combineReducers(listRedux);
    const persistedReducer = persistReducer(persistConfig, reducers);
    const store = configureStore({
      reducer: persistedReducer,
      devTools: { trace: true, traceLimit: 25 },
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          },
        }),
    });
    const persist = persistStore(store);
    /*Setup language*/
    i18next.use(initReactI18next).init({
      fallbackLng: 'vi',
      lng: 'vi',
      resources: {
        en: listLangEn,
        vi: listLangVi,
      },
      // have a common namespace used around the full app
      ns: ['common'],
      defaultNS: 'common',
      debug: false,
      // cache: {
      //   enabled: true
      // },
      interpolation: {
        escapeValue: false, // not needed for react as it does escape per default to prevent xss!
      },
    });
    /*End Setup language*/
    return (
      <Provider store={store}>
        <Suspense fallback={<Loading />}>
          <PersistGate loading={null} persistor={persist}>
            <I18nextProvider i18n={i18next}>
              <InitApp DefaultRoute={DefaultRoute} listRoutes={listRoutes} listNav={listNav} />
            </I18nextProvider>
          </PersistGate>
        </Suspense>
      </Provider>
    );
  }
  return null;
};
export default Bootstrap;
