import intlMessagesEN from './i18n/localization/en.json';
import intlMessagesVi from './i18n/localization/vi.json';

export default {
  name: 'LuckyDraw',
  dir: 'LuckyDraw',
  pathRoot: 'lucky-draw',
  routes: [
    {
      url: 'quay-so/:eventId/:prizeId',
      component: 'Page/LuckyDraw',
      meta: {
        authRoute: true,
      },
      layout: 'BlankLayout',
      props: {
        title: 'Trang chủ | Smart Event',
        titleI18n: 'LuckyDraw:lucky_draw_main_title',
        headerStyle: 'fill',
        exact: true,
      },
    },
    {
      url: '',
      component: 'Page/ListWinner',
      meta: {
        authRoute: true,
      },
      layout: 'FullLayout',
      props: {
        title: 'Trang chủ | Smart Event',
        titleI18n: 'LuckyDraw:title',
        headerStyle: 'fill',
        exact: true,
      },
    },
    {
      url: 'quay-so-trung-thuong/:eventId/:prizeId',
      component: 'Page/LuckyDrawSS',
      meta: {
        authRoute: true,
      },
      layout: 'BlankLayout',
      props: {
        title: 'Trang chủ | Smart Event',
        titleI18n: 'LuckyDraw:lucky_draw_main_title',
        headerStyle: 'fill',
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
};
