import intlMessagesEN from './i18n/localization/en.json';
import intlMessagesVi from './i18n/localization/vi.json';

export default {
  name: 'Account',
  dir: 'Account',
  pathRoot: 'account',
  routes: [
    {
      url: '',
      component: 'Page/index',
      meta: {
        authRoute: true,
      },
      layout: 'FullLayout',
      props: {
        title: 'Trang chủ | Smart Account',
        titleI18n: 'Account:title',
        headerStyle: 'fill',
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  roles: ["BTC"],
};
