export default [
  { key: 'Authenticate' },
  { key: 'Event' },
  { key: 'Public' },
  { key: 'CheckInOutPublic' },
  { key: 'Smtp' },
  { key: 'CheckInOut' },
  { key: 'LuckyDraw' },
  { key: 'InfoPin' },
  { key: 'InfoPrizesLuckydraw' },
  { key: 'InfoEventPayment' },
  { key: 'GroupInvite' },
  { key: 'UserRegister' },
  { key: 'UserInvite' },
  { key: 'Notification' },
  { key: 'Account' },
  { key: 'NotificationClient' },
  { key: 'DynamicGroupCode' },
  { key: 'DynamicOption' },
  { key: 'Gallery' },
  { key: 'Home' },
  { key: 'SendMailHistory' },
  { key: 'InfoCoupon' },
  { key: 'FormRegisterPublic' },
  { key: 'Wedding' },
  { key: 'TemplateECard' },
  { key: 'Welcome' },
  { key: 'Trade' },
  { key: 'GuestTrade' },
  { key: 'InfoEvent' },
  { key: 'InfoConfigRegister' },
  { key: 'MstTemplatePrint' },
  { key: 'MstGame' },
  { key: 'InfoUserGame' },
  { key: 'MstPromotion' },
  { key: 'FormPersonalRegister' },
  { key: 'MstTemplateEmail' },
];
