const ENV_ENVIRONMENT_BASE_URL_API = 'https://api-services.matbao.events/api';

const ENV_ENVIRONMENT_awsKeys = {
  bucket: 'smart-events-pro',
  accessKeyID: 'AKIAS37NMZ7DJVX4LQNX',
  secretKeyID: 'hKKFsMuiPfBlrv/Kj+19UdI1jjO4Tu0GvT3MngvJ',
  // accessKeyID: 'AKIAS37NMZ7DP4HTEXNU',
  // secretKeyID: 'FpcJqL09QVr+XTdriPjHyw2loz0WVx1pV61A1NQl',
  endpoint: 'https://smart-events-pro.s3.ap-southeast-1.amazonaws.com/',
  region: 'ap-southeast-1',
};

export const ENVIRONMENT_BASE_URL_API = ENV_ENVIRONMENT_BASE_URL_API;
export const ENVIRONMENT_awsKeys = ENV_ENVIRONMENT_awsKeys;

export const ENV_PUBLIC_API = 'https://builder.matbao.ws/api';