import intlMessagesEN from './i18n/localization/en.json';
import intlMessagesVi from './i18n/localization/vi.json';

export default {
  name: 'Welcome',
  dir: 'Welcome',
  pathRoot: 'welcome',
  routes: [
    {
      url: ':id/:slug',
      component: 'Page',
      meta: {
        authRoute: true,
      },
      layout: 'BlankLayout',
      props: {
        title: 'Welcome | Smart Event',
        titleI18n: 'Welcome:title',
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: 'Any',
};
