import { createSlice } from '@reduxjs/toolkit';

//notested: chưa test, testing: đang test, tested: đã test
const initialState = {
  language: 'vi',
  pin: undefined,
  statusTesting: undefined,
  loading: false,
};

export const CommonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setPinCode: (state, action) => {
      state.pin = action.payload;
    },
    setStatusTesting: (state, action) => {
      state.statusTesting = action.payload;
    },
  },
});

export default CommonSlice.reducer;
